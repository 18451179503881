import React, { useState } from 'react';
import { saveLead } from '../../../api/leads';

function AddLeadModal({ leads, setLeads, showAddLeadModal, setShowAddLeadModal }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [organization, setOrganization] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [leadSource, setLeadSource] = useState('');
    const [errors, setErrors] = useState([]);

    const handleCloseAddLeadModal = () => {
        setShowAddLeadModal(false);
    };

    const handleSubmit = async () => {
        const lead = {
            name,
            email,
            title,
            city,
            address,
            organization,
            description,
            status,
            lead_source: leadSource,
        };

        try {
            const newLead = await saveLead(lead);
            setLeads([newLead, ...leads]);
            setShowAddLeadModal(false);

            // Reset fields
            setName('');
            setEmail('');
            setTitle('');
            setCity('');
            setAddress('');
            setOrganization('');
            setDescription('');
            setStatus('');
            setLeadSource('');
            setErrors([]);
        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <div className={`modal fade ${showAddLeadModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg" style={{ maxHeight: 800, overflow: 'auto' }}>
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <div>
                            <h4 className="modal-title bold m-0">הוספת ליד</h4>
                        </div>
                        <span
                            type="button"
                            className="close ms-auto m-0 text-secondary"
                            onClick={handleCloseAddLeadModal}
                            style={{ fontSize: '25pt', fontWeight: '300' }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='שם'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='אימייל'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='תפקיד'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='עיר'
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='כתובת'
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='ארגון'
                                    value={organization}
                                    onChange={(e) => setOrganization(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='סטטוס'
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6 p-2'>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='מקור הליד'
                                    value={leadSource}
                                    onChange={(e) => setLeadSource(e.target.value)}
                                />
                            </div>
                            <div className='col-md-12 p-2'>
                                <textarea
                                    type="text"
                                    className='form-control'
                                    placeholder='תיאור'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            {errors && (
                                <div className="text-danger small">
                                    {errors.name && <span>{errors.name}</span>}
                                    {errors.email && <span>{errors.email}</span>}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='modal-footer border-0'>
                        <button className='btn btn-primary rounded' onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddLeadModal;
